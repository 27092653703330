import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140')
];

export const server_loads = [0,2,5];

export const dictionary = {
		"/(app)": [~6,[2],[3]],
		"/(auth)/after-signup": [~120],
		"/(onboarding)/company": [~131,[4]],
		"/(app)/dashboard/business-analyst": [13,[2],[3]],
		"/(app)/dashboard/finance-ratio": [14,[2],[3]],
		"/(app)/dashboard/sales": [15,[2],[3]],
		"/(app)/finance/account-payables": [16,[2],[3]],
		"/(app)/finance/account-payables/detail": [~17,[2],[3]],
		"/(app)/finance/account-receivables": [18,[2],[3]],
		"/(app)/finance/account-receivables/detail": [~19,[2],[3]],
		"/(app)/finance/balance-sheets": [~20,[2],[3]],
		"/(app)/finance/capital-flow": [~21,[2],[3]],
		"/(app)/finance/cash-flow": [~22,[2],[3]],
		"/(app)/finance/close-accounting-period": [~23,[2],[3]],
		"/(app)/finance/customer-credit-refunds": [~24,[2],[3]],
		"/(app)/finance/customer-credit-refunds/account-balance-mutation": [~25,[2],[3]],
		"/(app)/finance/customer-credit-refunds/add-pay-other-invoice": [~26,[2],[3]],
		"/(app)/finance/customer-credit-refunds/detail": [~27,[2],[3]],
		"/(app)/finance/customer-credit-refunds/journal-voucher": [~28,[2],[3]],
		"/(app)/finance/income-statement": [~29,[2],[3]],
		"/(app)/finance/income-statement/show": [~30,[2],[3]],
		"/(app)/finance/journals": [~31,[2],[3]],
		"/(app)/finance/journals/add": [~32,[2],[3]],
		"/(app)/finance/journals/detail": [~33,[2],[3]],
		"/(app)/finance/journals/edit/[id]": [~34,[2],[3]],
		"/(app)/finance/ledgers": [~35,[2],[3]],
		"/(app)/finance/payable-payments": [~36,[2],[3]],
		"/(app)/finance/payable-payments/add": [~37,[2],[3]],
		"/(app)/finance/payable-payments/detail": [~38,[2],[3]],
		"/(app)/finance/payable-payments/edit": [~39,[2],[3]],
		"/(app)/finance/payable-payments/journal-voucher": [~40,[2],[3]],
		"/(app)/finance/payment-invoices": [~41,[2],[3]],
		"/(app)/finance/payment-invoices/add": [~42,[2],[3]],
		"/(app)/finance/payment-invoices/detail": [~43,[2],[3]],
		"/(app)/finance/payment-invoices/edit": [~44,[2],[3]],
		"/(app)/finance/payment-invoices/journal-voucher": [~45,[2],[3]],
		"/(app)/finance/purchase-invoice-returns": [~46,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/add": [~47,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/detail": [~48,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/edit": [49,[2],[3]],
		"/(app)/finance/purchase-invoice-returns/journal-voucher": [~50,[2],[3]],
		"/(app)/finance/purchase-invoices": [51,[2],[3]],
		"/(app)/finance/purchase-invoices/add-payment": [~53,[2],[3]],
		"/(app)/finance/purchase-invoices/add": [~52,[2],[3]],
		"/(app)/finance/purchase-invoices/detail": [~54,[2],[3]],
		"/(app)/finance/purchase-invoices/edit": [55,[2],[3]],
		"/(app)/finance/purchase-invoices/journal-voucher": [~56,[2],[3]],
		"/(app)/finance/receivable-payments": [57,[2],[3]],
		"/(app)/finance/receivable-payments/journal-voucher": [~59,[2],[3]],
		"/(app)/finance/receivable-payments/[id]": [58,[2],[3]],
		"/(app)/finance/sale-invoices": [~60,[2],[3]],
		"/(app)/finance/sale-invoices/[id]": [~61,[2],[3]],
		"/(app)/finance/sale-invoices/[id]/edit": [~62,[2],[3]],
		"/(app)/finance/sale-returns": [~63,[2],[3]],
		"/(app)/finance/sale-returns/add": [~64,[2],[3]],
		"/(app)/finance/sale-returns/detail": [~65,[2],[3]],
		"/(app)/finance/sale-returns/edit": [66,[2],[3]],
		"/(app)/finance/sale-returns/journal-voucher": [~67,[2],[3]],
		"/(app)/finance/settings/account-classifications": [~68,[2],[3]],
		"/(app)/finance/settings/accounts-kpi": [~71,[2],[3]],
		"/(app)/finance/settings/accounts": [69,[2],[3]],
		"/(app)/finance/settings/accounts/balance": [70,[2],[3]],
		"/(app)/finance/settings/banks": [~72,[2],[3]],
		"/(app)/finance/settings/general-settings": [~73,[2],[3]],
		"/(app)/finance/settings/suppliers": [~74,[2],[3]],
		"/(app)/finance/vendor-debit-refunds": [~75,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/account-balance-mutation": [~76,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/add-pay-other-invoice": [~77,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/detail": [~78,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/edit": [~79,[2],[3]],
		"/(app)/finance/vendor-debit-refunds/journal-voucher": [~80,[2],[3]],
		"/(auth)/forget-password-old": [121],
		"/(preline)/(auth)/forget-password": [136,[5]],
		"/(app)/integration/channel-settings": [~81,[2],[3]],
		"/(app)/integration/facebook-ads": [82,[2],[3]],
		"/(app)/integration/google-ads": [83,[2],[3]],
		"/(app)/integration/manual-ads-payment": [84,[2],[3]],
		"/(app)/integration/marketplace": [~85,[2],[3]],
		"/(app)/integration/pos": [~86,[2],[3]],
		"/(app)/integration/tiktok-ads": [87,[2],[3]],
		"/(app)/integration/whatsapp": [~88,[2],[3]],
		"/(app)/inventory/categories": [89,[2],[3]],
		"/(app)/inventory/products": [~90,[2],[3]],
		"/(app)/inventory/products/[id]": [~91,[2],[3]],
		"/(app)/inventory/reports/product-age": [~92,[2],[3]],
		"/(app)/inventory/stock-opname": [93,[2],[3]],
		"/(app)/inventory/stock-opname/create": [~96,[2],[3]],
		"/(app)/inventory/stock-opname/[id]": [~94,[2],[3]],
		"/(app)/inventory/stock-opname/[id]/edit": [~95,[2],[3]],
		"/(app)/inventory/warehouses": [97,[2],[3]],
		"/(auth)/login-old": [122],
		"/(preline)/(auth)/login": [137,[5]],
		"/(auth)/logout": [~123],
		"/(app)/marketing/crm": [98,[2],[3]],
		"/(app)/marketing/kol": [99,[2],[3]],
		"/(app)/(preline)/notification": [7,[2],[3]],
		"/(app)/(preline)/notification/[id]": [8,[2],[3]],
		"/(app)/(preline)/onboarding": [9,[2],[3]],
		"/(onboarding)/payment/error": [132,[4]],
		"/(onboarding)/payment/finish": [~133,[4]],
		"/(onboarding)/payment/unfinish": [134,[4]],
		"/(onboarding)/pricing": [~135,[4]],
		"/(auth)/register-old": [~124],
		"/(preline)/(auth)/register-verification-check-new": [~139,[5]],
		"/(auth)/register-verification-check": [~126],
		"/(auth)/register-verification": [125],
		"/(preline)/(auth)/register": [~138,[5]],
		"/(app)/report/ads": [100,[2],[3]],
		"/(app)/report/marketplace": [101,[2],[3]],
		"/(app)/report/sales": [~102,[2],[3]],
		"/(auth)/resend-verification": [~127],
		"/(auth)/reset-password-old": [~128],
		"/(preline)/(auth)/reset-password": [~140,[5]],
		"/(auth)/role": [129],
		"/(app)/sales": [~103,[2],[3]],
		"/(app)/sales/channels": [106,[2],[3]],
		"/(app)/sales/customers": [107,[2],[3]],
		"/(app)/sales/entry": [~108,[2],[3]],
		"/(app)/sales/payment-methods": [~109,[2],[3]],
		"/(app)/sales/print/orders": [~110,[2],[3]],
		"/(app)/sales/print/receipts": [~111,[2],[3]],
		"/(app)/sales/receipt/[id]": [~112,[2],[3]],
		"/(app)/sales/shippings": [113,[2],[3]],
		"/(app)/sales/tax-settings": [114,[2],[3]],
		"/(app)/sales/upload": [115,[2],[3]],
		"/(app)/sales/[id]": [~104,[2],[3]],
		"/(app)/sales/[id]/edit": [~105,[2],[3]],
		"/(app)/settings/company-profiles": [~116,[2],[3]],
		"/(app)/settings/employees": [~117,[2],[3]],
		"/(app)/settings/profiles": [~118,[2],[3]],
		"/(app)/(preline)/shipping": [10,[2],[3]],
		"/(app)/(preline)/shipping/activation": [11,[2],[3]],
		"/(app)/(preline)/shipping/activation/result": [12,[2],[3]],
		"/(app)/tutorial": [119,[2],[3]],
		"/(auth)/users/invitee-verification-check": [~130]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';